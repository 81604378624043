<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog v-model="editPercentDialog">
      <v-card>
        <v-card-title>
          <h4>ویرایش درصد پرداختی بیمار</h4>
        </v-card-title>
        <v-card-text>
          <br />
          <v-text-field
            type="number"
            v-model="percent"
            label="درصد پرداختی (بازه 100 - 0)"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red-btn" outlined @click="editPercentDialog = false">
            لغو
          </v-btn>
          <v-btn
            class="primary-btn"
            outlined
            @click="editInsuranceService()"
            :disabled="percent > 100 || !percent || percent < 0 || busySubmit"
          >
            ثبت
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitPercentDialog">
      <v-card>
        <v-card-title>
          <h4>ثبت درصد پرداختی بیمار</h4>
        </v-card-title>
        <v-card-text>
          <br />
          <v-text-field
            type="number"
            v-model="percent"
            label="درصد پرداختی (بازه 100 - 0)"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red-btn" outlined @click="submitPercentDialog = false">
            لغو
          </v-btn>
          <v-btn
            class="primary-btn"
            outlined
            @click="newInsuranceService()"
            :disabled="percent > 100 || !percent || percent < 0 || busySubmit"
          >
            ثبت
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>ویرایش بیمه</h3>
      </v-card-title>
      <v-tabs fixed-tabs background-color="transparent" v-model="tab">
        <v-tab class="tab-title"> ویرایش بیمه </v-tab>
        <v-tab disabled class="tab-title"> هزینه های درمانی </v-tab>
        <v-tab class="tab-title"> گزارش عملکرد بیمه </v-tab>
      </v-tabs>
      <v-card class="br-card pa-3">
        <v-card-text>
          <div>
            <v-card class="pa-3">
              <v-tabs-items v-model="tab">
                <!-- ویرایش بیمه -->
                <v-tab-item>
                  <v-card class="mt-2">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          label="وضعیت"
                          v-model="userData.status"
                          :items="states"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          v-model="userData.name"
                          label="نام بیمه"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                    </v-row>

                    <v-btn
                      class="submit-btn primary-btn"
                      @click="EditInsurance()"
                      >ثبت</v-btn
                    >
                  </v-card>
                </v-tab-item>
                <!-- هزینه های درمانی -->
                <v-tab-item>
                  <v-card class="">
                    <v-card-title>
                      <h3>خدمات</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="Filter"
                        prepend-inner-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                        clearable
                        rounded
                        filled
                        class="text-right w-80 mb-2 search-input"
                      ></v-text-field>
                      <b-table
                        responsive
                        show-empty
                        :fields="Fields"
                        :items="Items"
                        empty-text="رکوردی برای نمایش وجود ندارد"
                        empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                        :busy="Busy"
                        :filter="Filter"
                        :current-page="CurrentPage"
                        :per-page="PerPage"
                        @filtered="onFiltered"
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              v-if="data.item.patientPercent > 0"
                              class="primary-btn"
                              @click="showModal(data.item)"
                              ><v-icon> edit</v-icon>
                            </v-btn>
                            <v-btn
                              v-else
                              class="text-light primary-btn pa-3"
                              @click="showModalSubmit(data.item)"
                              >ثبت
                            </v-btn>
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="CurrentPage"
                        :length="Math.ceil(TotalRows / PerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                      ></v-pagination>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- گزارش عملکرد بیمه  -->
                <v-tab-item>
                  <v-card class="">
                    <v-card-title>
                      <h3 class="primary--text">گزارش عملکرد بیمه</h3>
                    </v-card-title>
                    <v-card-text>
                      <div>
                        <v-row class="time-row">
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <span id="dateFrom3">
                              <v-text-field
                                outlined
                                dense
                                type="text"
                                append-icon="calendar_today"
                                v-model="dateFrom"
                                label=" تاریخ از "
                                :editable="true"
                                class="date-input"
                              >
                              </v-text-field>
                            </span>

                            <date-picker
                              v-model="dateFrom"
                              element="dateFrom3"
                              color="#00a7b7"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <span id="dateTo3">
                              <v-text-field
                                outlined
                                dense
                                type="text"
                                append-icon="calendar_today"
                                v-model="dateTo"
                                label=" تاریخ تا "
                                :editable="true"
                                class="date-input"
                              >
                              </v-text-field>
                            </span>

                            <date-picker
                              v-model="dateTo"
                              element="dateTo3"
                              color="#00a7b7"
                            />
                          </v-col>
                        </v-row>
                        <v-row class="time-row">
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <span id="timeFrom">
                              <v-text-field
                                outlined
                                dense
                                type="text"
                                append-icon="schedule"
                                v-model="timeFrom"
                                label=" ساعت از "
                                :editable="true"
                                class="date-input"
                              >
                              </v-text-field>
                            </span>

                            <date-picker
                              v-model="timeFrom"
                              element="timeFrom"
                              color="#00a7b7"
                              type="time"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <span id="timeTo">
                              <v-text-field
                                outlined
                                dense
                                type="text"
                                append-icon="schedule"
                                v-model="timeTo"
                                label=" ساعت تا "
                                :editable="true"
                                class="date-input"
                              >
                              </v-text-field>
                            </span>

                            <date-picker
                              v-model="timeTo"
                              element="timeTo"
                              color="#00a7b7"
                              type="time"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                              class="submit-btn primary-btn"
                              style="float: right"
                              @click="requests()"
                              :disabled="Busy"
                              >اعمال</v-btn
                            >
                          </v-col>
                        </v-row>
                        <hr />

                        <div v-if="requestLoaded">
                          <div v-if="requestsInfo.shifts">
                            <v-row class="mt-2">
                              <v-col>
                                <h5>
                                  گزارش عملکرد بیمه {{ userData.name }}
                                </h5></v-col
                              >
                              <v-col>
                                <v-btn
                                  style="float: left"
                                  class="secondary-btn"
                                  @click="$refs.requestsExcel.$el.click()"
                                  >دریافت اکسل گزارش</v-btn
                                >
                                <vue-excel-xlsx
                                  v-show="false"
                                  ref="requestsExcel"
                                  :data="requestsInfo.shifts"
                                  :columns="requestsExcelFields"
                                  :filename="
                                    'گزارش عملکرد بیمه ' + userData.name
                                  "
                                  :sheetname="currentDate"
                                >
                                  دریافت اکسل
                                </vue-excel-xlsx>
                              </v-col>
                            </v-row>
                            <v-row style="margin: 10px">
                              <v-col>
                                <h5>
                                  از:
                                  {{
                                    weekDict[
                                      moment(
                                        requestsInfo.startDate,
                                        "jYYYY/jMM/jDD"
                                      ).weekday()
                                    ]
                                  }}
                                  {{ requestsInfo.startDate }} -
                                  {{ requestsInfo.startTime }}
                                </h5>
                              </v-col>
                              <v-col>
                                <h5>
                                  تا:
                                  {{
                                    weekDict[
                                      moment(
                                        requestsInfo.endDate,
                                        "jYYYY/jMM/jDD"
                                      ).weekday()
                                    ]
                                  }}
                                  {{ requestsInfo.endDate }} -
                                  {{ requestsInfo.endTime }}
                                </h5>
                              </v-col>
                            </v-row>
                            <hr />
                            <h5>
                              <h4 class="primary--text text-center">
                                <strong>کل </strong>
                              </h4>

                              <v-row style="margin: 20px 10px">
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                  <div class="common-text common-div">
                                    <strong>
                                      <v-icon color="primary"
                                        >mdi-counter</v-icon
                                      >
                                      تعداد :
                                      {{ requestsInfo.totalCount }}
                                    </strong>
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                  <div class="common-text common-div">
                                    <strong>
                                      <v-icon color="primary"
                                        >mdi-cash-plus</v-icon
                                      >
                                      دریافتی :
                                      {{
                                        Number(
                                          requestsInfo.totalCost
                                        ).toLocaleString()
                                      }}
                                      ریال
                                    </strong>
                                  </div>
                                </v-col>
                              </v-row>
                            </h5>

                            <hr />
                            <b-table
                              responsive
                              show-empty
                              class="mt-2"
                              :fields="requestsFields"
                              :items="requestsInfo.shifts"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                              empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                              :busy="Busy"
                              :filter="requestsFilter"
                              :current-page="requestedCurrentPage"
                              :per-page="requestedPerPage"
                              @filtered="requestonFiltered"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    typeof data.value === "number"
                                      ? Number(data.value).toLocaleString()
                                      : data.value
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    data.index + PerPage * (CurrentPage - 1) + 1
                                  }}
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <v-pagination
                              v-model="requestedCurrentPage"
                              :length="
                                Math.ceil(requestedTotalRows / requestedPerPage)
                              "
                              :total-visible="5"
                              prev-icon="arrow_back"
                              next-icon="arrow_forward"
                            ></v-pagination>
                          </div>
                          <div v-else>
                            <div class="text-center common-text">
                              نتیجه ای در بازه زمانی خواسته شده یافت نشد.
                            </div>
                          </div>
                        </div>
                        <div v-else class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                            size="50"
                            width="10"
                          ></v-progress-circular>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      tab: null,
      editPercentDialog: false,
      submitPercentDialog: false,
      userData: {},
      PerPage: 10,
      CurrentPage: 1,
      TotalRows: "",
      percent: "",
      Busy: false,
      busySubmit: false,
      Filter: "",
      requestsFilter: "",
      selectedService: "",
      selectedInsurService: "",
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "patientPercent", label: "درصد بیمار" },
        { key: "operation", label: "مدیریت " },
      ],
      Items: [],
      requestsFields: [
        { key: "index", label: "#" },
        { key: "shiftName", label: "نام شیفت" },
        { key: "ShiftStart", label: "آغاز شیفت" },
        { key: "ShiftFinish", label: "پایان شیفت" },
        { key: "count", label: "تعداد " },
        { key: "cost", label: "دریافتی " },
      ],
      requestsExcelFields: [
        { field: "shiftName", label: "نام شیفت" },
        { field: "ShiftStart", label: "آغاز شیفت" },
        { field: "ShiftFinish", label: "پایان شیفت" },
        { field: "count", label: "تعداد " },
        { field: "cost", label: "دریافتی " },
      ],
      requestsInfo: [],
      requestedPerPage: 10,
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      requestedCurrentPage: 1,
      requestedTotalRows: "",
      requestLoaded: false,
      moment: moment,
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
    };
  },

  methods: {
    requests() {
      this.Busy = true;
      this.requestLoaded = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/report",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
            insuranceId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.requestsInfo = res.data;
            this.requestLoaded = true;
            if (this.requestsInfo.shifts) {
              this.requestedTotalRows = this.requestsInfo.shifts.length;
              this.requestedCurrentPage = 1;
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.requestLoaded = true;
          this.Busy = false;
        });
    },
    showModal(service) {
      //NOTE showing modal of editting
      this.selectedInsurService = service.insurServiceId;
      this.percent = service.patientPercent;
      this.editPercentDialog = true;
    },
    showModalSubmit(service) {
      //NOTE show submite modal
      this.selectedService = service;
      this.percent = service.percent;
      this.submitPercentDialog = true;
    },
    EditInsurance() {
      //NOTE editting insurance and send it to server
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/edit",
          {
            insuranceId: this.$route.params.id,
            name: this.userData.name,
            status: this.userData.status,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast("بیمه با موفقیت ویرایش شد.", "success");
            this.$router.push("/admin/insurance");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    showInsuranceInfo() {
      //NOTE getting insurance info when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/id",
          {
            insuranceId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userData = res.data;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    patientPercent() {
      //NOTE getting patient percent from server
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/allServicesIsurance",
          {
            insuranceId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    newInsuranceService() {
      //NOTE setting new patient percent
      this.busySubmit = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/newInsuranceService",
          {
            insuranceId: this.$route.params.id,
            serviceId: String(this.selectedService.id),
            percent: this.percent,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.busySubmit = false;
            this.submitPercentDialog = false;
            this.toast("درصد بیمار با موفقیت ثبت شد.", "success");
            this.patientPercent();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.busySubmit = false;
            this.submitPercentDialog = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.busySubmit = false;
          this.submitPercentDialog = false;
        });
    },
    editInsuranceService() {
      //NOTE editting insurance info and patient percent and send it to server
      this.busySubmit = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/editInsuranceService",
          {
            insuranceServiceId: this.selectedInsurService,
            percent: this.percent,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.busySubmit = false;
            this.editPercentDialog = false;
            this.toast("درصد بیمار با موفقیت ویرایش شد.", "success");
            this.patientPercent();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.busySubmit = false;
            this.editPercentDialog = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.busySubmit = false;
          this.editPercentDialog = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    requestonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.requestedTotalRows = filteredItems.length;
      this.requestedCurrentPage = 1;
    },
  },

  mounted() {
    this.showInsuranceInfo();
    this.patientPercent();
    this.requests();
  },
};
</script>
